// extracted by mini-css-extract-plugin
export var container = "careers-module--container--2nNXM";
export var description = "careers-module--description--t96ME";
export var descriptionContainer = "careers-module--description-container--zpsQ8";
export var descriptionHeading = "careers-module--description-heading--DFVpK";
export var descriptionImage = "careers-module--description-image--dUtNI";
export var descriptionText = "careers-module--description-text--lHzM5";
export var greenText = "careers-module--green-text--b5Ms9";
export var heading = "careers-module--heading--agkAH";
export var openRolesSection = "careers-module--open-roles-section--hRmQ6";
export var openRolesSubtitle = "careers-module--open-roles-subtitle--n7ATr";
export var openRolesTitle = "careers-module--open-roles-title--t0ixZ";
export var role = "careers-module--role--W6ckw";
export var roleContainer = "careers-module--role-container--1bm3j";
export var roleLink = "careers-module--role-link--29bbB";
export var roleLocation = "careers-module--role-location--xjz76";
export var roleTitle = "careers-module--role-title--nsIny";
export var roleType = "careers-module--role-type--Ibqzw";
export var text = "careers-module--text--WuWzM";
export var title = "careers-module--title--qRbd7";